import {
  ImageContainer,
  LoginContent,
  LoginFormContainer,
  NewUserButton,
  NewUserContainer,
  NewUserContent,
  ForgotPasswordLink
} from './styles';

import { getImage } from '../../services/getAssets';
import { useAuth } from '../../hooks/useAuth';
import { LoginForm } from './LoginForm';
import { LoginMFA } from './LoginMFA';
import { TbUserPlus } from "react-icons/tb";
import { Link } from 'react-router-dom';

export const Login = () => {
  const { step } = useAuth();

  return (
    <>
      <LoginContent>
        <LoginFormContainer>
          <ImageContainer>
            <img src={getImage('logo.png')} alt="" id='logo'/>
          </ImageContainer>

          {step === 1 ? (
            <>
              <LoginForm />

              <NewUserContainer>
                <NewUserContent>
                  <div>
                    <h3>Ainda não tem uma conta no {process.env.REACT_APP_COMPANY_NAME}?</h3>
                  </div>
                </NewUserContent>

                <NewUserButton to="/cadastro">
                  <TbUserPlus />
                  Abra sua conta
                </NewUserButton>

              </NewUserContainer>
            </>
          ) : <LoginMFA />}
        </LoginFormContainer>

        <img id = "image-login"src="/images/login-image.png" alt="" />
      </LoginContent>
    </>
  )
}
